import { Menu, MenuItem } from '@blueprintjs/core';
import * as Popover from '@radix-ui/react-popover';
import cx from 'classnames';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { sprinkles } from 'components/ds';
import { CustomMenuOptionConfig, V2TwoDimensionChartInstructions } from 'constants/types';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { setSelectedDashboardDrilldownInfo } from 'reducers/dashboardInteractionsReducer';
import { ChartMenuInfo, getIsIframe, setChartMenu } from 'reducers/dashboardLayoutReducer';
import { DashboardStates, ReportBuilderStates } from 'reducers/rootReducer';
import { fetchDrilldownDataThunk } from 'reducers/thunks/dashboardDataThunks/fetchDataPanelThunks';
import { DrilldownVariable } from 'types/dashboardTypes';
import { DrilldownEntryPointInfo } from 'types/dataPanelTemplate';
import { ChartColumnInfoUtils } from 'utils/chartColumnInfoUtils';
import { getColorColumn } from 'utils/colorColUtils';
import { isCategorySelected } from 'utils/drilldownUtils';

type Props = {
  canViewUnderlyingData: boolean;
  chartMenuInfo: ChartMenuInfo;
  dataPanelId: string;
  instructions: V2TwoDimensionChartInstructions | undefined;
  customMenuOptions?: CustomMenuOptionConfig[];
  drilldownVar?: DrilldownVariable;
  selectedColorColName?: string;
  excludedCategories?: (string | number)[];
  drilldownEntryPoints: Record<string, DrilldownEntryPointInfo>;
  dashboardIdToNameMap: Record<number, string>;

  setCategorySelect?: (category: string, colorColumn?: string) => void;
};

export const DrilldownChartMenu: FC<Props> = ({
  canViewUnderlyingData,
  chartMenuInfo,
  dataPanelId,
  customMenuOptions,
  instructions,
  selectedColorColName,
  excludedCategories,
  setCategorySelect,
  drilldownVar,
  drilldownEntryPoints,
  dashboardIdToNameMap,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('DrilldownChartMenu');

  const { layoutId, isIframe } = useSelector(
    (state: DashboardStates | ReportBuilderStates) => ({
      layoutId: 'dashboardLayout' in state ? state.dashboardLayout.layoutId : null,
      isIframe: 'dashboardLayout' in state ? getIsIframe(state.dashboardLayout) : false,
    }),
    shallowEqual,
  );

  const onCustomMenuOptionClicked = useCallback(
    (jsEvent: string, category: string, subCategory?: string) => {
      const detail = { category, color: subCategory };
      if (isIframe) {
        window.parent.postMessage({ event: jsEvent, detail }, '*');
      } else {
        window.dispatchEvent(new CustomEvent(jsEvent, { detail }));
      }
    },
    [isIframe],
  );

  const { category, subCategory, chartX, chartY } = chartMenuInfo;
  const hasColorOptions = !!instructions?.colorColumnOptions?.length;

  const matchingDrilldownEntryPoints = useMemo(
    () => getMatchingDashboardDrilldownEntryPoints(drilldownEntryPoints, instructions, subCategory),
    [drilldownEntryPoints, instructions, subCategory],
  );

  const onUnderlyingDataClick = (category: string, subCategory?: string) => {
    const categoryColumn = instructions?.categoryColumn;
    if (!categoryColumn) return;

    const colorColumn = getColorColumn(instructions, selectedColorColName);

    dispatch(
      fetchDrilldownDataThunk({
        dataPanelId,
        categoryColumn,
        category,
        subCategoryColumn: colorColumn,
        subCategory: hasColorOptions ? subCategory : undefined,
        excludedCategories: category === 'Other' ? excludedCategories : undefined,
      }),
    );
  };

  if (
    !canViewUnderlyingData &&
    !setCategorySelect &&
    !customMenuOptions?.length &&
    !matchingDrilldownEntryPoints.length
  )
    return null;

  const isCurrentCategorySelected = isCategorySelected(drilldownVar, category, subCategory);
  return (
    <Popover.Root onOpenChange={(open) => !open && dispatch(setChartMenu(null))} open={true}>
      <Popover.Anchor asChild>
        <div
          className={sprinkles({ position: 'absolute' })}
          style={{ left: chartX, top: chartY }}
        />
      </Popover.Anchor>
      <Popover.Portal container={layoutId ? document.getElementById(layoutId) : undefined}>
        {/* Added key so it repaints if another part of the chart is clicked */}
        <Popover.Content
          align="start"
          key={`${chartX}-${chartY}`}
          onClick={() => dispatch(setChartMenu(null))}
          side="right"
          style={{ zIndex: 10 }}>
          <Menu
            className={cx(
              sprinkles({ boxShadow: 'md' }),
              embedSprinkles({ body: 'primaryWithoutColor' }),
            )}>
            {canViewUnderlyingData ? (
              <MenuItem
                onClick={() => onUnderlyingDataClick(category, subCategory)}
                text={t('view_underlying_data')}
              />
            ) : null}
            {setCategorySelect ? (
              <MenuItem
                onClick={() =>
                  setCategorySelect(category, hasColorOptions ? subCategory : undefined)
                }
                text={isCurrentCategorySelected ? 'Remove Filter' : 'Filter Category'}
              />
            ) : null}
            {matchingDrilldownEntryPoints.map(([entryPointId, entryPointInfo]) => (
              <MenuItem
                key={entryPointId}
                onClick={() => {
                  dispatch(
                    setSelectedDashboardDrilldownInfo({
                      drilldownEntryPointId: entryPointId,
                      sourceDataPanelId: dataPanelId,
                      selectedPrimaryField: category,
                      selectedSecondaryField: subCategory,
                    }),
                  );
                }}
                text={`Drill into chart ${
                  dashboardIdToNameMap[entryPointInfo.destinationDashboardId]
                }`}
              />
            ))}
            {customMenuOptions?.map((menuOption) =>
              menuOption.name && menuOption.customJSEventName ? (
                <MenuItem
                  onClick={() =>
                    onCustomMenuOptionClicked(
                      menuOption.customJSEventName as string,
                      category,
                      subCategory,
                    )
                  }
                  text={menuOption.name}
                />
              ) : null,
            )}
          </Menu>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

// TODO(zifanxiang): Write tests for this method.
// Exposed for testing only.
/**
 * @param subcategory The currently selected secondary column value. This can be undefined if there
 *    no secondary column is selected.
 * @returns An array of the drilldown entry points that match the current selected columns on the
 *    chart.
 */
export const getMatchingDashboardDrilldownEntryPoints = (
  drilldownEntryPoints: Record<string, DrilldownEntryPointInfo>,
  instructions: V2TwoDimensionChartInstructions | undefined,
  subCategory?: string,
): [string, DrilldownEntryPointInfo][] => {
  // There might still be some old data panels that do not have drilldown entry points defined
  // (should be an empty object in that case but since we did not do a migration, it might be
  // undefined).
  if (!drilldownEntryPoints) {
    return [];
  }

  const categoryColumn = instructions?.categoryColumn?.column;
  if (!categoryColumn) {
    return [];
  }

  const firstColorColumn = instructions?.colorColumnOptions?.[0]?.column;
  return Object.entries(drilldownEntryPoints).filter(([, entryPointInfo]) => {
    const entryPointSourceColumns = entryPointInfo.sourceChartColumns;
    return (
      !entryPointSourceColumns[0] ||
      (ChartColumnInfoUtils.equals(entryPointSourceColumns[0], categoryColumn) &&
        (!subCategory ||
          !firstColorColumn ||
          !entryPointSourceColumns[1] ||
          ChartColumnInfoUtils.equals(entryPointSourceColumns[1], firstColorColumn)))
    );
  });
};
