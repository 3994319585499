import {
  AggregateProperty,
  AggregationOption,
  And,
  BooleanPropertyValue,
  CalendarInterval,
  CalendarIntervalGrouping,
  Computation,
  DatePart,
  DatePartGrouping,
  DatePropertyValue,
  DateTimePropertyValue,
  DecimalIntervalGrouping,
  DecimalPropertyValue,
  DoublePropertyValue,
  Equal,
  ExportColumnOptions,
  Aggregation as FidoAggregation,
  Filter,
  FormulaProperty,
  GreaterThan,
  GreaterThanOrEqual,
  Grouping,
  In,
  IntegerIntervalGrouping,
  IntegerPropertyValue,
  LessThan,
  LessThanOrEqual,
  Not,
  Null,
  Or,
  Property,
  PropertyType,
  PropertyValue,
  SortDirection,
  SourceProperty,
  StringContains,
  StringPropertyValue,
  ValueGrouping,
} from '@explo-tech/fido-api';
import { DateTime } from 'luxon';

import {
  AGGREGATIONS_TYPES,
  DATE,
  DATE_PART_INPUT_AGG,
  DOUBLE,
  FLOAT,
  NUMBER_TYPES,
  SchemaDataType,
  TIME_COLUMN_TYPES,
} from 'constants/dataConstants';
import {
  AggedChartColumnInfo,
  Aggregation,
  CategoryChartColumnInfo,
  ChartColumnInfo,
  FilterOperationInstructions,
  FilterValueDateType,
  FilterValueNumberRangeType,
  FilterValueRelativeDateType,
  FilterValueType,
  OPERATION_TYPES,
  SchemaChange,
  SortInfo,
  SortOrder,
  StringDisplayFormat,
} from 'constants/types';
import { PIVOT_ROW_LIMIT } from 'reportBuilderContent/thunks/utils';
import { ColumnConfigWithName, ColumnConfigs, GroupByBucket } from 'types/columnTypes';
import { VisualizeOperation } from 'types/dataPanelTemplate';
import {
  PIVOT_AGG_TYPES,
  PivotAgg,
  TREND_GROUP_OPTION_TO_PIVOT_AGG,
  TrendGroupingOptions,
} from 'types/dateRangeTypes';
import {
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_EMPTY,
  FILTER_OPS_MULTISELECT,
  FILTER_OPS_NEGATED,
  FILTER_OPS_NUMBER,
  FILTER_OPS_NUMBER_RANGE,
  FILTER_OPS_RELATIVE_PICKER,
  FILTER_OPS_STRING,
  FilterOperator,
} from 'types/filterOperations';
import { DEFAULT_CUSTOM_FORMULA, getAggColNameBase } from 'utils/V2ColUtils';
import { getDatesFromDateRelativeOption } from 'utils/dateTimeUtils';
import { titleCase } from 'utils/graphUtils';

import { makeAbsentOrNotBlank } from './fidoUtils';

const DEFAULT_QUERY_LIMIT = 5000;

export const getEmptyComputation = (): Computation => ({
  properties: [],
  filter: null,
  having: null,
  sorts: [],
  groupings: [],
});

export const processSort = (sortInfo: SortInfo[] | undefined, computation: Computation) => {
  (sortInfo ?? []).forEach((colInfo) => {
    computation.sorts.push({
      propertyId: colInfo.column.name,
      sortDirection: colInfo.order === SortOrder.ASC ? SortDirection.ASC : SortDirection.DESC,
    });
  });
};

export const getQueryLimit = (
  visualizeOperation: VisualizeOperation,
  maxDataPointsOverride?: number,
) => {
  switch (visualizeOperation.operation_type) {
    case OPERATION_TYPES.VISUALIZE_DENSITY_MAP:
    case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
      return (
        visualizeOperation.instructions.VISUALIZE_GEOSPATIAL_CHART?.rowLimit ??
        maxDataPointsOverride ??
        DEFAULT_QUERY_LIMIT
      );
    case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
    case OPERATION_TYPES.VISUALIZE_TABLE:
      return visualizeOperation.instructions.VISUALIZE_TABLE.rowsPerPage ?? 50;
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
      return maxDataPointsOverride ?? DEFAULT_QUERY_LIMIT;
    case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2:
      return PIVOT_ROW_LIMIT;
    default:
      return DEFAULT_QUERY_LIMIT;
  }
};

export const getFilterValue = (
  value: FilterValueType,
  operator: FilterOperator,
  coerceTo: SchemaDataType,
  timezone: string,
): { value?: PropertyValue; values?: PropertyValue[] } | null | undefined => {
  // this block checks the no value filters
  if (operator === FilterOperator.BOOLEAN_IS_FALSE) {
    const propertyValue: BooleanPropertyValue = { value: false, '@type': 'boolean' };
    return { value: propertyValue };
  } else if (operator === FilterOperator.BOOLEAN_IS_TRUE) {
    const propertyValue: BooleanPropertyValue = { value: true, '@type': 'boolean' };
    return { value: propertyValue };
  } else if (operator === FilterOperator.DATE_TODAY) {
    const now = DateTime.local().setZone(timezone);

    return {
      values: [
        getTemporalFilterValue(now.startOf('day'), timezone, coerceTo),
        getTemporalFilterValue(now.endOf('day'), timezone, coerceTo),
      ],
    };
  } else if (FILTER_OPS_EMPTY.has(operator)) {
    return {};
  }

  if (value === undefined) return null;

  if (FILTER_OPS_NUMBER.has(operator)) {
    return { value: getNumericFilterValue(value as number, coerceTo) };
  } else if (FILTER_OPS_STRING.has(operator)) {
    const propertyValue: StringPropertyValue = { value: value as string, '@type': 'string' };
    return { value: propertyValue };
  } else if (FILTER_OPS_MULTISELECT.has(operator)) {
    // if this filter is configured at the chart level, its coming in as a string representation of a list
    // and we need to convert it to a list here
    if (typeof value === 'string') value = JSON.parse(value);

    const propertyValues: PropertyValue[] = [...(value as string[] | number[])].map((v) => {
      if ([FilterOperator.NUMBER_IS_IN, FilterOperator.NUMBER_IS_NOT_IN].includes(operator)) {
        return getNumericFilterValue(v as number, coerceTo);
      } else {
        const propertyValue: StringPropertyValue = { value: v as string, '@type': 'string' };
        return propertyValue;
      }
    });
    return { values: propertyValues };
  } else if (FILTER_OPS_NUMBER_RANGE.has(operator)) {
    const { min, max } = value as FilterValueNumberRangeType;
    if (!min || !max) return null;
    return { values: [getNumericFilterValue(min, coerceTo), getNumericFilterValue(max, coerceTo)] };
  } else if (FILTER_OPS_DATE_PICKER.has(operator)) {
    const date = (value as FilterValueDateType).startDate;
    if (!date) return null;
    if (new Set([FilterOperator.DATE_IS, FilterOperator.DATE_IS_NOT]).has(operator)) {
      return {
        values: [
          getTemporalFilterValue(DateTime.fromISO(date), timezone, coerceTo),
          getTemporalFilterValue(DateTime.fromISO(date).endOf('day'), timezone, coerceTo),
        ],
      };
    }

    return { value: getTemporalFilterValue(DateTime.fromISO(date), timezone, coerceTo) };
  } else if (FILTER_OPS_DATE_RANGE_PICKER.has(operator)) {
    const { startDate, endDate } = value as FilterValueDateType;
    if (!startDate || !endDate) return null;

    return {
      values: [
        getTemporalFilterValue(DateTime.fromISO(startDate), timezone, coerceTo),
        getTemporalFilterValue(DateTime.fromISO(endDate), timezone, coerceTo),
      ],
    };
  } else if (FILTER_OPS_RELATIVE_PICKER.has(operator)) {
    const { number, relativeTimeType } = value as FilterValueRelativeDateType;
    if (!number || !relativeTimeType) return null;

    const { startDate, endDate } = getDatesFromDateRelativeOption(
      relativeTimeType.id,
      number,
      operator === FilterOperator.DATE_PREVIOUS,
    );

    return {
      values: [
        getTemporalFilterValue(startDate, timezone, coerceTo),
        getTemporalFilterValue(endDate, timezone, coerceTo),
      ],
    };
  }

  return null;
};

const getTemporalFilterValue = (
  value: DateTime,
  timezone: string,
  coerceTo: SchemaDataType,
): DateTimePropertyValue | DatePropertyValue => {
  // we don't need to convert dates to a timezone because dates are intrinsically timezone-naive
  if (coerceTo === DATE) {
    return {
      value: value.toISODate(),
      '@type': PropertyType.DATE,
    };
  }

  // date times should be set to the selected hour, but with the offset set to the local time. For example,
  // if the user is in EST selects 12:00, we should be sending to FIDO 12:00 EST so that it can filter on
  // the correct times in UTC
  return {
    value: value.setZone(timezone, { keepLocalTime: true }).toISO(),
    '@type': PropertyType.DATETIME,
  };
};

const getNumericFilterValue = (
  value: number,
  coerceTo: SchemaDataType,
): IntegerPropertyValue | DoublePropertyValue | DecimalPropertyValue => {
  return {
    value: value,
    '@type': coerceTo === FLOAT ? 'decimal' : coerceTo === DOUBLE ? 'double' : 'integer',
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createFilter = (
  columnName: string,
  columnType: string,
  operation: FilterOperator,
  valueSource: FilterValueType | undefined,
  timezone: string,
  property: Property,
) => {
  const value = getFilterValue(valueSource, operation, columnType as SchemaDataType, timezone);
  if (value == null) return null;

  switch (operation) {
    case FilterOperator.NUMBER_EQ:
    case FilterOperator.STRING_IS:
    case FilterOperator.NUMBER_NEQ:
    case FilterOperator.STRING_IS_NOT:
    case FilterOperator.BOOLEAN_IS_TRUE:
    case FilterOperator.BOOLEAN_IS_FALSE: {
      if (!value.value) return null;
      return {
        '@type': 'eq',
        value: value.value,
        property,
      };
    }
    case FilterOperator.DATE_IS:
    case FilterOperator.DATE_IS_NOT:
    case FilterOperator.DATE_TODAY:
    case FilterOperator.DATE_IS_BETWEEN:
    case FilterOperator.NUMBER_IS_BETWEEN:
    case FilterOperator.DATE_PREVIOUS:
    case FilterOperator.DATE_NEXT: {
      if (!value.values) return null;
      return {
        '@type': 'and',
        values: [
          {
            '@type': 'gte',
            value: value.values[0],
            property,
          },
          {
            '@type': 'lte',
            value: value.values[1],
            property,
          },
        ],
      };
    }
    case FilterOperator.STRING_IS_NOT_IN:
    case FilterOperator.STRING_IS_IN:
    case FilterOperator.NUMBER_IS_IN:
    case FilterOperator.NUMBER_IS_NOT_IN: {
      if (!value.values) return null;
      return {
        '@type': 'in',
        values: value.values,
        property,
      };
    }
    case FilterOperator.NUMBER_LT:
    case FilterOperator.DATE_LT: {
      if (!value.value) return null;
      return {
        '@type': 'lt',
        value: value.value,
        property,
      };
    }
    case FilterOperator.NUMBER_GT:
    case FilterOperator.DATE_GT: {
      if (!value.value) return null;
      return {
        '@type': 'gt',
        value: value.value,
        property,
      };
    }
    case FilterOperator.NUMBER_LTE:
    case FilterOperator.DATE_LTE: {
      if (!value.value) return null;
      return {
        '@type': 'lte',
        value: value.value,
        property,
      };
    }
    case FilterOperator.NUMBER_GTE:
    case FilterOperator.DATE_GTE: {
      if (!value.value) return null;
      return {
        '@type': 'gte',
        value: value.value,
        property,
      };
    }
    case FilterOperator.STRING_CONTAINS:
    case FilterOperator.STRING_DOES_NOT_CONTAIN: {
      if (!value.value) return null;
      return {
        '@type': 'str-ctns',
        value: value.value as StringPropertyValue,
        property,
        caseInsensitive: true,
      };
    }
    case FilterOperator.IS_EMPTY:
    case FilterOperator.IS_NOT_EMPTY: {
      return {
        '@type': 'null',
        property,
      };
    }
  }
};

export const getFilter = (
  columnName: string,
  columnType: string,
  operation: FilterOperator,
  valueSource: FilterValueType | undefined,
  timezone: string,
) => {
  const value = getFilterValue(valueSource, operation, columnType as SchemaDataType, timezone);
  if (value == null) return null;

  switch (operation) {
    case FilterOperator.NUMBER_EQ:
    case FilterOperator.STRING_IS:
    case FilterOperator.NUMBER_NEQ:
    case FilterOperator.STRING_IS_NOT: {
      if (!value.value) return null;
      const filter: Equal = {
        '@type': 'eq',
        value: value.value,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.DATE_IS:
    case FilterOperator.DATE_IS_NOT:
    case FilterOperator.DATE_TODAY:
    case FilterOperator.DATE_IS_BETWEEN: {
      if (!value.values) return null;
      const startFilter: GreaterThanOrEqual = {
        '@type': 'gte',
        value: value.values[0],
        propertyId: columnName,
      };
      const endFilter: LessThanOrEqual = {
        '@type': 'lte',
        value: value.values[1],
        propertyId: columnName,
      };
      const and: And = {
        '@type': 'and',
        values: [startFilter, endFilter],
      };
      return and;
    }
    case FilterOperator.BOOLEAN_IS_TRUE:
    case FilterOperator.BOOLEAN_IS_FALSE: {
      // isVarReference shouldn't ever be set for these
      if (!value?.value) return null;
      const filter: Equal = {
        '@type': 'eq',
        value: value.value,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.STRING_IS_NOT_IN:
    case FilterOperator.STRING_IS_IN:
    case FilterOperator.NUMBER_IS_IN:
    case FilterOperator.NUMBER_IS_NOT_IN: {
      if (!value.values) return null;
      const filter: In = {
        '@type': 'in',
        values: value.values,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.NUMBER_LT:
    case FilterOperator.DATE_LT: {
      if (!value.value) return null;
      const filter: LessThan = {
        '@type': 'lt',
        value: value.value,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.NUMBER_GT:
    case FilterOperator.DATE_GT: {
      if (!value.value) return null;
      const filter: GreaterThan = {
        '@type': 'gt',
        value: value.value,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.NUMBER_LTE:
    case FilterOperator.DATE_LTE: {
      if (!value.value) return null;
      const filter: LessThanOrEqual = {
        '@type': 'lte',
        value: value.value,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.NUMBER_GTE:
    case FilterOperator.DATE_GTE: {
      if (!value.value) return null;
      const filter: GreaterThanOrEqual = {
        '@type': 'gte',
        value: value.value,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.NUMBER_IS_BETWEEN:
    case FilterOperator.DATE_PREVIOUS:
    case FilterOperator.DATE_NEXT: {
      if (!value?.values) return null;
      const startFilter: GreaterThanOrEqual = {
        '@type': 'gte',
        value: value.values[0],
        propertyId: columnName,
      };
      const endFilter: LessThanOrEqual = {
        '@type': 'lte',
        value: value.values[1],
        propertyId: columnName,
      };
      const and: And = {
        '@type': 'and',
        values: [startFilter, endFilter],
      };
      return and;
    }
    case FilterOperator.STRING_CONTAINS:
    case FilterOperator.STRING_DOES_NOT_CONTAIN: {
      if (!value.value) return null;
      const contains: StringContains = {
        '@type': 'str-ctns',
        value: value.value as StringPropertyValue,
        propertyId: columnName,
        caseInsensitive: true,
      };
      return contains;
    }
    case FilterOperator.IS_EMPTY:
    case FilterOperator.IS_NOT_EMPTY: {
      const isNull: Null = {
        '@type': 'null',
        propertyId: columnName,
      };
      return isNull;
    }
  }
};

export const aggregationMap: Record<
  Aggregation,
  { agg: FidoAggregation; aggOption?: AggregationOption }
> = {
  [Aggregation.COUNT]: { agg: FidoAggregation.COUNT },
  [Aggregation.COUNT_DISTINCT]: { agg: FidoAggregation.COUNT_DISTINCT },
  [Aggregation.AVG]: { agg: FidoAggregation.AVG },
  [Aggregation.SUM]: { agg: FidoAggregation.SUM },
  [Aggregation.MIN]: { agg: FidoAggregation.MIN },
  [Aggregation.MAX]: { agg: FidoAggregation.MAX },
  [Aggregation['25_PERCENTILE']]: {
    agg: FidoAggregation.PERCENTILE,
    aggOption: { decimalValue: 0.25 },
  },
  [Aggregation.MEDIAN]: { agg: FidoAggregation.PERCENTILE, aggOption: { decimalValue: 0.5 } },
  [Aggregation['75_PERCENTILE']]: {
    agg: FidoAggregation.PERCENTILE,
    aggOption: { decimalValue: 0.75 },
  },

  // TODOs
  [Aggregation.FORMULA]: { agg: FidoAggregation.COUNT },
  [Aggregation.FIRST]: { agg: FidoAggregation.COUNT },
};

export const processFilter = (
  filterInfo: FilterOperationInstructions | undefined,
  timezone: string,
) => {
  if (!filterInfo || filterInfo.filterClauses.length === 0) return null;

  const filters: Filter[] = [];

  filterInfo.filterClauses.forEach(({ filterOperation, filterColumn, filterValue }) => {
    if (!filterOperation || !filterColumn) return null;

    // when we're done implementing this'll just be Filter | null
    const filter: Filter | null | undefined = getFilter(
      filterColumn.name,
      filterColumn.type,
      filterOperation.id,
      filterValue,
      timezone,
    );

    if (!filter) return null;

    if (FILTER_OPS_NEGATED.has(filterOperation.id)) {
      const not: Not = {
        '@type': 'not',
        value: filter,
      };

      filters.push(not);
    } else {
      filters.push(filter);
    }
  });

  if (filters.length === 1) {
    return filters[0];
  } else if (filters.length > 1) {
    if (filterInfo.matchOnAll) {
      const andFilter: And = { values: filters, '@type': 'and' };
      return andFilter;
    } else {
      const orFilter: Or = { values: filters, '@type': 'or' };
      return orFilter;
    }
  }
  return null;
};

export const getDateBucketTargetPropertyId = (name: string, pivotAggId: PivotAgg) => {
  return `${PIVOT_AGG_TYPES[pivotAggId].name.toLowerCase().replaceAll(' ', '_')}_${name}`;
};

export const getIntervalGroupingTargetPropertyId = (bucketSize: number, propertyId: string) => {
  const stringifiedBucketSize = bucketSize
    .toString()
    .toLowerCase()
    .replaceAll('.', '_')
    .replaceAll('-', 'N');

  return `${propertyId}_bucket_${stringifiedBucketSize}`;
};

export const getGrouping = (
  {
    column,
    bucket,
    bucketSize,
  }: {
    column: ChartColumnInfo;
    bucket?: GroupByBucket;
    bucketSize?: number;
  },
  timezone: string,
): Grouping | null => {
  const propertyId = column.name ?? '';

  if (NUMBER_TYPES.has(column.type ?? '') && bucketSize) {
    if (Number.isInteger(bucketSize)) {
      const grouping: IntegerIntervalGrouping = {
        '@type': 'integer-interval',
        interval: bucketSize,
        targetPropertyId: getIntervalGroupingTargetPropertyId(bucketSize, propertyId),
        propertyId,
      };
      return grouping;
    } else {
      const grouping: DecimalIntervalGrouping = {
        '@type': 'decimal-interval',
        interval: bucketSize,
        targetPropertyId: getIntervalGroupingTargetPropertyId(bucketSize, propertyId),
        propertyId,
      };
      return grouping;
    }
  } else if (!TIME_COLUMN_TYPES.has(column.type ?? '')) {
    const grouping: ValueGrouping = {
      '@type': 'value',
      propertyId,
      targetPropertyId: propertyId,
    };
    return grouping;
  }

  if (!bucket || bucket.id === DATE_PART_INPUT_AGG) return null;
  const targetPropertyId = getDateBucketTargetPropertyId(propertyId, bucket.id);

  switch (bucket.id) {
    case PivotAgg.DATE_PART_HOUR: {
      const grouping: DatePartGrouping = {
        '@type': 'date-part',
        datePart: DatePart.HOUR_OF_DAY,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_PART_MONTH: {
      const grouping: DatePartGrouping = {
        '@type': 'date-part',
        datePart: DatePart.MONTH_OF_YEAR,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_PART_MONTH_DAY: {
      const grouping: DatePartGrouping = {
        '@type': 'date-part',
        datePart: DatePart.DAY_OF_MONTH,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_PART_WEEK_DAY: {
      const grouping: DatePartGrouping = {
        '@type': 'date-part',
        datePart: DatePart.DAY_OF_WEEK,
        propertyId,
        targetPropertyId,

        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_HOUR: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.HOUR,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_DAY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.DAY,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_WEEK: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.WEEK,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_MONTH: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.MONTH,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_YEAR: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.YEAR,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    default:
      return null;
  }
};

export const getTrendGrouping = (
  {
    column,
    grouping,
  }: {
    column: ChartColumnInfo;
    grouping: TrendGroupingOptions;
  },
  timezone: string,
): Grouping | null => {
  const propertyId = column.name ?? '';

  const targetPropertyId = getDateBucketTargetPropertyId(
    propertyId,
    TREND_GROUP_OPTION_TO_PIVOT_AGG[grouping].id,
  );
  switch (grouping) {
    case TrendGroupingOptions.HOURLY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.HOUR,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case TrendGroupingOptions.DAILY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.DAY,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case TrendGroupingOptions.WEEKLY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.WEEK,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case TrendGroupingOptions.MONTHLY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.MONTH,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case TrendGroupingOptions.YEARLY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.YEAR,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    default:
      return null;
  }
};

export const getAggregationOrFormula = (col: AggedChartColumnInfo, index?: number) => {
  if (col.agg.id === Aggregation.FORMULA) {
    // if not set, set the custom formula to this "arbitrary" constant so that the query runs properly.
    // Note: historically, this is what embeddo did
    const formula =
      col.agg.formula && col.agg.formula.trim() !== '' ? col.agg.formula : DEFAULT_CUSTOM_FORMULA;

    // guardrail to not duplicate property names if a customer has multiple custom_formulae.
    // dashboards name custom formulas custom_formula, so we're guaranteed dupes if a customer
    // has multiple
    const columnName =
      // note that column.name should never actually be null
      (col.column.name ?? '') + (index !== undefined ? `_${index.toString()}` : '');

    const property: FormulaProperty = {
      '@type': 'formula',
      targetPropertyId: getAggColNameBase(columnName, col.agg.id),
      formula,
    };

    return property;
  } else {
    const { agg, aggOption } = aggregationMap[col.agg.id];
    const property: AggregateProperty = {
      '@type': 'aggregate',
      propertyId: agg === FidoAggregation.COUNT ? null : col.column.name ?? '', // TODO: Should be moved to FIDO
      targetPropertyId: getAggColNameBase(col.column.name ?? '', col.agg.id),
      aggregation: agg,
      aggregationOption: aggOption,
    };

    return property;
  }
};

export const getScatterPlotSourceProperty = (col: CategoryChartColumnInfo) => {
  return {
    '@type': 'source',
    propertyId: col.column.name ?? '',
    targetPropertyId: null,
  } as SourceProperty;
};

export const getAdHocFilterInfo = (
  filterInfo: FilterOperationInstructions | undefined,
  visualizeOp: VisualizeOperation,
): FilterOperationInstructions | undefined => {
  if (
    !filterInfo ||
    visualizeOp.operation_type !== OPERATION_TYPES.VISUALIZE_TABLE ||
    !visualizeOp.instructions.VISUALIZE_TABLE.schemaDisplayOptions
  ) {
    return filterInfo;
  }

  // for tables, you can join a column to another column, so we need to sort on the displayed column
  // rather than the underlying value
  const { schemaDisplayOptions, baseSchemaList } = visualizeOp.instructions.VISUALIZE_TABLE;

  const newFilterClauses = filterInfo.filterClauses.map((filter) => {
    const schemaDisplayOption = schemaDisplayOptions[filter.filterColumn?.name ?? ''];
    if (
      schemaDisplayOption &&
      'urlColumnName' in schemaDisplayOption &&
      schemaDisplayOption.format === StringDisplayFormat.LINK &&
      schemaDisplayOption.urlColumnName
    ) {
      const joinedColumn = baseSchemaList?.find(
        (col) => col.name === schemaDisplayOption.urlColumnName,
      );
      if (joinedColumn) {
        return { ...filter, filterColumn: { name: joinedColumn.name, type: joinedColumn.type } };
      }
    }
    return filter;
  });

  const newFilterInfo: FilterOperationInstructions = {
    ...filterInfo,
    filterClauses: newFilterClauses,
  };
  return newFilterInfo;
};

export const generateExploreExportColumnOptions = (
  computation: Computation,
  schemaChange: SchemaChange[],
) => {
  const options: ExportColumnOptions[] = [];

  if (schemaChange == null || schemaChange.length === 0) return options;

  const mapToOption = (item: Grouping | Property) => {
    // in practice this should never be null
    const targetPropertyId = item.targetPropertyId ?? '';
    const columnSchemaChange = schemaChange.find((column) => column.col === targetPropertyId);
    // If there is no schema change for this column, then this column has been removed and should
    // not be exported.
    if (!columnSchemaChange) {
      return;
    }

    const displayName = makeAbsentOrNotBlank(columnSchemaChange.newColName);
    options.push({ targetPropertyId, displayName: displayName });
  };

  computation.properties.forEach(mapToOption);
  computation.groupings.forEach(mapToOption);

  return options;
};

export const generateReportBuilderExportColumnOptions = (
  computation: Computation,
  schemaChange: ColumnConfigs,
) => {
  const options: ExportColumnOptions[] = [];

  if (schemaChange == null || Object.keys(schemaChange).length === 0) return options;

  // groupings should be added first since they are displayed first in the table
  computation.groupings.forEach((grouping) => {
    const maybeConfiguredName = (schemaChange[grouping.propertyId] as ColumnConfigWithName)?.name;

    let displayName =
      makeAbsentOrNotBlank(maybeConfiguredName) ??
      makeAbsentOrNotBlank(makeFriendlyName(grouping.propertyId));

    if ('calendarInterval' in grouping || 'datePart' in grouping) {
      displayName = `${displayName} (${getEmbeddoBucketFromFidoBucket(grouping)})`;
    }

    options.push({
      // in practice this should never be null
      targetPropertyId: grouping.targetPropertyId ?? '',
      displayName,
    });
  });

  computation.properties.forEach((property) => {
    // in practice this should never be null
    const targetPropertyId = property.targetPropertyId ?? '';

    if (property['@type'] === 'formula') {
      const maybeConfiguredName = (schemaChange[targetPropertyId] as ColumnConfigWithName)?.name;

      options.push({
        targetPropertyId: property.targetPropertyId ?? '',
        displayName: makeAbsentOrNotBlank(maybeConfiguredName) ?? 'Custom Formula',
      });
    } else if (property['@type'] === 'aggregate') {
      // in practice this should never be null
      const originalColumnName = property.propertyId ?? '';
      const maybeConfiguredName = (schemaChange[originalColumnName] as ColumnConfigWithName)?.name;

      if (maybeConfiguredName) {
        const agg = getEmbeddoAggFromFidoAgg(property);
        options.push({
          targetPropertyId: property.targetPropertyId ?? '',
          displayName: `${maybeConfiguredName} (${
            agg ? AGGREGATIONS_TYPES[agg].name : 'Aggregation'
          })`,
        });
      } else {
        options.push({
          targetPropertyId: property.targetPropertyId ?? '',
          displayName: makeAbsentOrNotBlank(makeFriendlyName(originalColumnName)),
        });
      }
    } else {
      const maybeConfiguredName = (schemaChange[property.propertyId] as ColumnConfigWithName)?.name;
      options.push({
        targetPropertyId: property.targetPropertyId ?? '',
        displayName:
          makeAbsentOrNotBlank(maybeConfiguredName) ??
          makeAbsentOrNotBlank(makeFriendlyName(property.propertyId)),
      });
    }
  });

  return options;
};

const makeFriendlyName = (name: string) => titleCase(name.replaceAll('_', ' '));

const getEmbeddoAggFromFidoAgg = (aggregation: AggregateProperty) => {
  if (aggregation.aggregation === FidoAggregation.COUNT) return Aggregation.COUNT;
  if (aggregation.aggregation === FidoAggregation.COUNT_DISTINCT) return Aggregation.COUNT_DISTINCT;
  if (aggregation.aggregation === FidoAggregation.SUM) return Aggregation.SUM;
  if (aggregation.aggregation === FidoAggregation.MIN) return Aggregation.MIN;
  if (aggregation.aggregation === FidoAggregation.MAX) return Aggregation.MAX;
  if (aggregation.aggregation === FidoAggregation.PERCENTILE) {
    if (aggregation.aggregationOption?.decimalValue === 0.25) return Aggregation['25_PERCENTILE'];
    if (aggregation.aggregationOption?.decimalValue === 0.5) return Aggregation.MEDIAN;
    if (aggregation.aggregationOption?.decimalValue === 0.75) return Aggregation['75_PERCENTILE'];
  }
};

const getEmbeddoBucketFromFidoBucket = (grouping: CalendarIntervalGrouping | DatePartGrouping) => {
  if (grouping['@type'] === 'calendar-interval') {
    if (grouping.calendarInterval === CalendarInterval.YEAR) return PivotAgg.DATE_YEAR;
    if (grouping.calendarInterval === CalendarInterval.MONTH) return PivotAgg.DATE_MONTH;
    if (grouping.calendarInterval === CalendarInterval.WEEK) return PivotAgg.DATE_WEEK;
    if (grouping.calendarInterval === CalendarInterval.DAY) return PivotAgg.DATE_DAY;
    if (grouping.calendarInterval === CalendarInterval.HOUR) return PivotAgg.DATE_HOUR;
  } else {
    if (grouping.datePart === DatePart.DAY_OF_MONTH) return PivotAgg.DATE_PART_MONTH_DAY;
    if (grouping.datePart === DatePart.DAY_OF_WEEK) return PivotAgg.DATE_PART_WEEK_DAY;
    if (grouping.datePart === DatePart.HOUR_OF_DAY) return PivotAgg.DATE_PART_HOUR;
    if (grouping.datePart === DatePart.MONTH_OF_YEAR) return PivotAgg.DATE_PART_MONTH;
  }
};
