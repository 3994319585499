import { createSlice } from '@reduxjs/toolkit';

import { DashboardVariable } from 'types/dashboardTypes';
import { FilterOperator } from 'types/filterOperations';

import type { PayloadAction } from '@reduxjs/toolkit';

export interface DrilldownSourceInfo {
  sourceDashboardId: number;
  sourceDataPanelId: string;
}

export interface DrilldownFilterInfo {
  operatorId: FilterOperator;
  filterValue: DashboardVariable;
}

interface DrilldownsState {
  currentSourceInfos: DrilldownSourceInfo[];
  // A map of dataset id to dataset column to drilldown filter info (which contains the operator
  // and value to apply to the dataset column).
  // The drilldown dataset filters will be used to automatically generated filter clauses for all
  // data against the sepcified dataset.
  drilldownDatasetFilters: Record<string, Record<string, DrilldownFilterInfo>>;
}

const initialState: DrilldownsState = {
  currentSourceInfos: [],
  drilldownDatasetFilters: {},
};

const drilldownsReducer = createSlice({
  name: 'drilldowns',
  initialState,
  reducers: {
    clearDrilldownsState: (state) => {
      state.currentSourceInfos = [];
      state.drilldownDatasetFilters = {};
    },
    setCurrentSourceInfos: (state, action: PayloadAction<DrilldownSourceInfo[]>) => {
      state.currentSourceInfos = action.payload;
    },
    setDrilldownFilters: (
      state,
      action: PayloadAction<Record<string, Record<string, DrilldownFilterInfo>>>,
    ) => {
      state.drilldownDatasetFilters = action.payload;
    },
  },
});

export const { clearDrilldownsState, setCurrentSourceInfos, setDrilldownFilters } =
  drilldownsReducer.actions;
export default drilldownsReducer.reducer;
