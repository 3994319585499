import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { sprinkles } from 'components/ds';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DashboardStates } from 'reducers/rootReducer';
import * as RD from 'remotedata';

interface Props {
  globalStyleConfig: GlobalStyleConfig;
}

export const DashboardDrilldownsBanner: FC<Props> = ({ globalStyleConfig }) => {
  const { currentSourceInfos, dashboardHierarchy, currentDashboard } = useSelector(
    (state: DashboardStates) => {
      return {
        currentSourceInfos: state.drilldowns.currentSourceInfos,
        dashboardHierarchy: 'dashboard' in state ? state.dashboard.dashboardHierarchy : RD.Idle(),
        currentDashboard:
          'dashboard' in state ? state.dashboard.currentDashboard : state.embedDashboard.dashboard,
      };
    },
    shallowEqual,
  );

  const dashboardBreadcumbInfo = useMemo(() => {
    if (!RD.isSuccess(dashboardHierarchy)) {
      return [];
    }

    return currentSourceInfos.map((info) => {
      const dashboard = dashboardHierarchy.data.dashboards[info.sourceDashboardId];

      if (!dashboard) {
        return {};
      }

      return {
        name: dashboard.name,
        id: dashboard.id,
      };
    });
  }, [currentSourceInfos, dashboardHierarchy]);

  if (dashboardBreadcumbInfo.length === 0 || !RD.isSuccess(currentDashboard)) {
    return null;
  }

  return (
    <div
      className={sprinkles({ heading: 'h3', paddingX: 'sp3', paddingTop: 'sp2' })}
      style={{ backgroundColor: globalStyleConfig.base.backgroundColor }}>
      {dashboardBreadcumbInfo.map((breadcrumbInfo, index) => {
        return (
          <span key={breadcrumbInfo.id}>
            <Link
              className={sprinkles({ textDecoration: 'none' })}
              to={{
                pathname: `/dashboard/${breadcrumbInfo.id}`,
                // TODO(zifanxiang): Preserve the drilldown variables as well as the source infos.
                state: { updatedDrilldownSourceInfos: currentSourceInfos.slice(0, index) },
              }}>
              {breadcrumbInfo.name}
            </Link>
            <span> {` > `} </span>
          </span>
        );
      })}
      <span>{currentDashboard.data.name}</span>
    </div>
  );
};
