import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Modal, Select, sprinkles } from 'components/ds';
import { SelectItems } from 'components/ds/Select';
import { NUMBER_TYPES } from 'constants/dataConstants';
import { ROUTE_PROVIDERS } from 'constants/routes';
import { DrilldownColumnIndexToTypeMap, DrilldownColumnType } from 'constants/types';
import { setSelectedDashboardDrilldownInfo } from 'reducers/dashboardInteractionsReducer';
import { DashboardStates } from 'reducers/rootReducer';
import { getDashboardIdToNameMap } from 'reducers/selectors';
import * as RD from 'remotedata';
import { DashboardVariable } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import {
  COLUMN_TYPE_TO_SUPPORTED_FILTER_OPERATOR_IDS_MAP,
  FilterOperator,
} from 'types/filterOperations';
import { isEmpty } from 'utils/standard';

export const DashboardDrilldownOptionsModal: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedFilterOperators, setSelectedFilterOperators] = useState<
    Record<string, FilterOperator | undefined>
  >({});

  const {
    selectedDashboardDrilldownInfo,
    versionHierarchy,
    currentDashboardId,
    dashboardIdToNameMap,
    drilldownSourceInfos,
    drilldownDatasetFilters,
  } = useSelector((state: DashboardStates) => {
    return {
      selectedDashboardDrilldownInfo: state.dashboardInteractions.selectedDashboardDrilldownInfo,
      // TODO(zifanxiang/tstenz): Have the embed correctly retrieve the version hierarchy.
      versionHierarchy:
        'dashboardEditConfig' in state ? state.dashboardEditConfig.versionHierarchy : RD.Idle(),
      currentDashboardId:
        'dashboardEditConfig' in state ? state.dashboardEditConfig.currentDashboardId : null,
      dashboardIdToNameMap: getDashboardIdToNameMap(state),
      drilldownSourceInfos: state.drilldowns.currentSourceInfos,
      drilldownDatasetFilters: state.drilldowns.drilldownDatasetFilters,
    };
  });

  const isDrillInButtonDisabled = useMemo(() => {
    return (
      Object.values(selectedFilterOperators).some((operatorId) => !operatorId) ||
      isEmpty(selectedFilterOperators)
    );
  }, [selectedFilterOperators]);

  if (!selectedDashboardDrilldownInfo || !RD.isSuccess(versionHierarchy) || !currentDashboardId) {
    return null;
  }

  const sourceDataPanel: DataPanel =
    versionHierarchy.data.dashboardVersions[currentDashboardId].configuration.data_panels[
      selectedDashboardDrilldownInfo.sourceDataPanelId
    ];

  const drilldownEntryPoint =
    sourceDataPanel.drilldownEntryPoints[selectedDashboardDrilldownInfo.drilldownEntryPointId];
  const entryPointColumns = drilldownEntryPoint.sourceChartColumns;

  return (
    <Modal
      isOpen
      onClose={() => {
        dispatch(setSelectedDashboardDrilldownInfo(null));
      }}
      primaryButtonProps={{
        disabled: isDrillInButtonDisabled,
        text: 'Drill in',
        onClick: () => {
          const updatedDrilldownSourceInfos = [
            ...drilldownSourceInfos,
            {
              sourceDashboardId: drilldownEntryPoint.sourceDashboardId,
              sourceDataPanelId: sourceDataPanel.id,
            },
          ];
          const drilldownVariables: Record<string, DashboardVariable> = {};
          const columnNameToValueMap: Record<string, DashboardVariable> = {};
          drilldownEntryPoint.sourceChartColumns.forEach((sourceChartColumn, index) => {
            const columnType = DrilldownColumnIndexToTypeMap[index];
            const columnVariableName = `drilldown_${columnType.toLocaleLowerCase()}`;
            const sourceDashboardName = dashboardIdToNameMap[drilldownEntryPoint.sourceDashboardId];
            const drilldownVariableName = `${sourceDashboardName}.${sourceDataPanel.provided_id}.${columnVariableName}`;
            const drilldownVariableValue = getDrilldownVariableValue(
              selectedDashboardDrilldownInfo.selectedPrimaryField,
              selectedDashboardDrilldownInfo.selectedSecondaryField,
              columnType,
              sourceChartColumn.type || '',
            );
            drilldownVariables[drilldownVariableName] = drilldownVariableValue;
            columnNameToValueMap[sourceChartColumn.name || ''] = drilldownVariableValue;
          });
          const updatedDrilldownDatasetFilters = { ...drilldownDatasetFilters };
          Object.entries(selectedFilterOperators).forEach(([columnName, operatorId]) => {
            if (!operatorId) {
              return;
            }
            updatedDrilldownDatasetFilters[sourceDataPanel.table_id] = {
              [columnName]: {
                operatorId,
                filterValue: columnNameToValueMap[columnName],
              },
            };
          });
          history.push(ROUTE_PROVIDERS.DASHBOARD(`${drilldownEntryPoint.destinationDashboardId}`), {
            drilldownVariables: drilldownVariables,
            updatedDrilldownSourceInfos: updatedDrilldownSourceInfos,
            updatedDrilldownDatasetFilters: updatedDrilldownDatasetFilters,
          });
        },
      }}
      size="small"
      title={`Drilldown into ${dashboardIdToNameMap[drilldownEntryPoint.destinationDashboardId]}`}>
      <div className={sprinkles({ paddingX: 'sp3' })}>
        {entryPointColumns.map((entryPointColumn) => {
          const columnType = entryPointColumn.type;
          if (!columnType) {
            return null;
          }
          const supportedFilterOperators =
            COLUMN_TYPE_TO_SUPPORTED_FILTER_OPERATOR_IDS_MAP.get(columnType);

          if (!supportedFilterOperators) {
            return null;
          }
          const drilldownColumnOperatorValues: SelectItems<string> = [];
          supportedFilterOperators.forEach((operator) => {
            drilldownColumnOperatorValues.push({
              label: operator.name,
              value: operator.id,
            });
          });
          return (
            <>
              <div
                className={sprinkles({
                  heading: 'h4',
                  marginBottom: 'sp1',
                })}>{`Filter operator for ${entryPointColumn.name}`}</div>
              <Select
                key={entryPointColumn.name}
                onChange={(operatorId) => {
                  setSelectedFilterOperators({
                    ...selectedFilterOperators,
                    [entryPointColumn.name || '']: operatorId as FilterOperator,
                  });
                }}
                selectedValue={selectedFilterOperators[entryPointColumn.name || '']}
                values={drilldownColumnOperatorValues}></Select>
            </>
          );
        })}
      </div>
    </Modal>
  );
};

const getDrilldownVariableValue = (
  category: string,
  subCategory: string | undefined,
  drilldownColumnType: DrilldownColumnType,
  columnDataType: string,
): DashboardVariable => {
  switch (drilldownColumnType) {
    case DrilldownColumnType.PRIMARY:
      return castDrilldownValueToColumnDataType(category, columnDataType);
    case DrilldownColumnType.SECONDARY:
      return castDrilldownValueToColumnDataType(subCategory || '', columnDataType);
  }
  throw new Error('Unsupported column type');
};

const castDrilldownValueToColumnDataType = (
  value: string,
  columnDataType: string,
): DashboardVariable => {
  // TODO(zifanxiang): Cast correctly for all types of data (dates).
  if (NUMBER_TYPES.has(columnDataType)) {
    return parseFloat(value);
  }
  return value;
};
